<template>
  <div>
    <div class="my-5">
      <div v-if="base64Image && copyPastePIX" class="flex flex-col-reverse items-center justify-center ">
      <div class="w-full mb-6">
        <p class="text-center mb-2 text-lg">
          {{ $t("components.pix.copyPaste") }}
        </p>
        <div class="typable_barcode p-3">
          <img class="ml-1 mr-2" height="50" width="50" src="../../assets/images/logo-pix.png" />
          <p id="copyPastePix" class="line" :class="{ lineCompact: compact }"> {{ copyPastePIX }}</p>
          <button class="btn-action btn-copy" @click="copyToClipboardPix">
            {{  copyButtonTitlePix }}
          </button>
        </div>
      </div>
      <div class="flex flex-col items-center" >
        <p class="text-center text-lg">
          {{  $t("components.pix.qrCode") }}
        </p>
        <img :src="base64Image"/>
      </div>
    </div>
      <p class="text-center mb-3 text-lg">
        {{ $t("components.paymentSlip.digitableLine") }}
      </p>
      <div class="typable_barcode">
        <IconBarcode class="icon" />
        <p class="line" :class="{ lineCompact: compact }">
          {{ lineCode }}
        </p>
        <button class="btn-action btn-copy" @click="copyToClipboard">
          {{ copyButtonTitle }}
        </button>
        <a v-if="!isDesktop" :href="printLink" target="_blank" class="btn-action btn-pdf">
          PDF
        </a>
        <button v-else class="btn-action btn-pdf" @click="next">
          PDF
        </button>
      </div>
      <p v-if="simple === false && dueAt !== null" class="text-sm ml-1 mt-2">
        <b>{{ this.$t("currency.sign") }} {{ parseInt(amount) }},00</b>
        {{ $t("components.paymentSlip.expireLabel") }}
        <b>{{ formatDate(dueAt) }}</b>
      </p>
      <input id="line-code" type="hidden" :value="lineCode" />
    </div>


  </div>
</template>

<script>
import dayjs from "dayjs-ext";
import { IconBarcode } from "../Icons";
import { mapGetters } from "vuex";
import { BANKSLIP_PDF_VIEW } from "@/router/constants";

export default {
  name: "PaymentBankslip",
  components: {
    IconBarcode,
  },
  props: {
    base64Image: {
      type: String,
      required: false
    },
    copyPastePIX: {
      type: String,
      required: false
    },
    lineCode: {
      type: String,
      required: true,
    },
    printLink: {
      type: String,
      required: true,
    },
    amount: {
      type: Number,
      required: true,
    },
    dueAt: {
      type: String,
      required: true,
    },
    simple: {
      type: Boolean,
      required: false,
    },
    compact: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      copyButtonTitle: "COPIAR",
      copyButtonTitlePix: "COPIAR"
    };
  },
  computed: {
    ...mapGetters({
        isDesktop: `isDesktop`,
    }),
  },
  methods: {
    formatDate(date) {
      return dayjs(date).format("DD/MM/YYYY");
    },
    copyToClipboard() {
      const textToCopy = document.querySelector("#line-code");
      textToCopy.setAttribute("type", "text");
      textToCopy.select();
      document.execCommand("copy");
      textToCopy.setAttribute("type", "hidden");
      this.copyButtonTitle = "Copiado!";
    },
    copyToClipboardPix() {
      const textToCopy = document.getElementById("copyPastePix")
      navigator.clipboard.writeText(textToCopy.innerText)
      this.copyButtonTitlePix = "Copiado!";
    },
      async next() {
            this.$router.push({
                    name: BANKSLIP_PDF_VIEW,
                });
            return true;
        },
  },
};
</script>

<style lang="scss" scoped>
.resp-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
.typable_barcode {
  @apply flex flex-no-wrap border px-1 text-sm justify-between place-items-center;
  .btn-action {
    @apply cursor-pointer border px-2 mr-2 block text-center;
  }
  .btn-copy {
    @apply text-xs text-white text-black bg-white ml-2;
    &:hover {
      @apply bg-gray-100;
    }
  }
  .btn-pdf {
    @apply bg-blue-500 text-white border-blue-600;
    &:hover {
      @apply bg-blue-700;
    }
  }
  .icon {
    @apply flex-initial m-2 h-8 w-8;
  }
  .line {
    @apply text-lg text-center whitespace-no-wrap overflow-hidden flex-grow;
    text-overflow: ellipsis;
  }
  .lineCompact {
    @apply text-xs;
  }
}
@screen sm {
  .typable_barcode {
    .btn-action {
      @apply py-1;
    }
    .icon {
      @apply h-6 w-6;
    }
    .btn-action {
      @apply text-sm;
    }
  }
}
@screen md {
  .typable_barcode {
    .btn-action {
      @apply py-1 w-24;
    }
    .icon {
      @apply h-8 w-8;
    }
  }
}
@screen lg {
  .typable_barcode {
    .btn-action {
      @apply py-2 w-28;
    }
    .icon {
      @apply h-10 w-10;
    }
  }
}
@screen xl {
  .typable_barcode {
    .btn-action {
      @apply py-2 w-32;
    }
    .icon {
      @apply h-12 w-12;
    }
  }
}
</style>
