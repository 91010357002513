var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "m-2 p-4 pb-0" }, [
    _c("iframe", {
      staticClass: "iframe",
      attrs: {
        src: _vm.getBankslipUrl,
        title: "description",
        frameborder: "0",
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }