var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "m-0 p-0" }, [
    _vm.getLineCode
      ? _c(
          "div",
          [
            _c("div", { staticClass: "text-center justify-center flex mt-5" }, [
              _vm._v(
                " " + _vm._s(_vm.$t("bankslip_view.bankslip_ahead")) + ": "
              ),
            ]),
            _c("payment-bank-slip", {
              attrs: {
                printLink: _vm.getPrintLink,
                lineCode: _vm.getLineCode,
                amount: _vm.getAmount,
                dueAt: _vm.getDueAt,
                "base64-image": _vm.getBase64Image,
                "copy-paste-p-i-x": _vm.getCopyPastePIX,
              },
            }),
          ],
          1
        )
      : _c("div", [
          _c("p", [_vm._v(_vm._s(_vm.$t("bankslip_view.no_bankslip_found")))]),
          _c("br"),
          _c("p", [_vm._v(_vm._s(_vm.$t("bankslip_view.bankslip_info")))]),
          _c("br"),
          _c("p", [
            _vm._v(" " + _vm._s(_vm.$t("bankslip_view.doubt")) + " "),
            _c(
              "a",
              {
                staticClass: "font-bold underline",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    _vm.$intercom.showNewMessage(
                      _vm.$t("bankslip_view.intercom_msg")
                    )
                  },
                },
              },
              [_vm._v(" " + _vm._s(_vm.$t("bankslip_view.support")) + " ")]
            ),
          ]),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }