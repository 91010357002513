<template>
  <div class="m-0 p-0">
    <div v-if="getLineCode">
      <div class="text-center justify-center flex mt-5">
        {{ $t("bankslip_view.bankslip_ahead") }}:
      </div>
      <payment-bank-slip
        :printLink="getPrintLink"
        :lineCode="getLineCode"
        :amount="getAmount"
        :dueAt="getDueAt"
        :base64-image="getBase64Image"
        :copy-paste-p-i-x="getCopyPastePIX"
      />
    </div>

    <div v-else>
      <p>{{ $t("bankslip_view.no_bankslip_found") }}</p>
      <br />
      <p>{{ $t("bankslip_view.bankslip_info") }}</p>
      <br />
      <p>
        {{ $t("bankslip_view.doubt") }}
        <a
          href="#"
          class="font-bold underline"
          @click="$intercom.showNewMessage($t('bankslip_view.intercom_msg'))"
        >
          {{ $t("bankslip_view.support") }}
        </a>
      </p>
    </div>
  </div>
</template>

<script>
import { BANKSLIP_VIEW } from "@/router/constants";
import PaymentBankSlip from "@/components/PaymentSlip/PaymentBankSlip.vue";
import { mapGetters } from "vuex";
import { STORE_MODULE } from "@/store/constants/store";

export default {
  name: BANKSLIP_VIEW,
  components: { PaymentBankSlip },

  computed: {
    ...mapGetters({
      subscription: `${STORE_MODULE}/subscription`,
    }),

    getLineCode() {
      return this.subscription?.last_bankslip_barcode;
    },
    getPrintLink() {
      return this.subscription?.last_bankslip_url;
    },
    getAmount() {
      return this.subscription?.amount;
    },
    getDueAt() {
      return this.subscription?.last_bankslip_expiration_at;
    },
    getCopyPastePIX() {
      return this.subscription?.last_pix?.copyPaste
    },
    getBase64Image() {
      return this.subscription?.last_pix?.qrCodeImageBase64
    }
  },
};
</script>
<style scoped></style>
