var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "my-5" }, [
      _vm.base64Image && _vm.copyPastePIX
        ? _c(
            "div",
            {
              staticClass: "flex flex-col-reverse items-center justify-center",
            },
            [
              _c("div", { staticClass: "w-full mb-6" }, [
                _c("p", { staticClass: "text-center mb-2 text-lg" }, [
                  _vm._v(
                    " " + _vm._s(_vm.$t("components.pix.copyPaste")) + " "
                  ),
                ]),
                _c("div", { staticClass: "typable_barcode p-3" }, [
                  _c("img", {
                    staticClass: "ml-1 mr-2",
                    attrs: {
                      height: "50",
                      width: "50",
                      src: require("../../assets/images/logo-pix.png"),
                    },
                  }),
                  _c(
                    "p",
                    {
                      staticClass: "line",
                      class: { lineCompact: _vm.compact },
                      attrs: { id: "copyPastePix" },
                    },
                    [_vm._v(" " + _vm._s(_vm.copyPastePIX))]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "btn-action btn-copy",
                      on: { click: _vm.copyToClipboardPix },
                    },
                    [_vm._v(" " + _vm._s(_vm.copyButtonTitlePix) + " ")]
                  ),
                ]),
              ]),
              _c("div", { staticClass: "flex flex-col items-center" }, [
                _c("p", { staticClass: "text-center text-lg" }, [
                  _vm._v(" " + _vm._s(_vm.$t("components.pix.qrCode")) + " "),
                ]),
                _c("img", { attrs: { src: _vm.base64Image } }),
              ]),
            ]
          )
        : _vm._e(),
      _c("p", { staticClass: "text-center mb-3 text-lg" }, [
        _vm._v(
          " " + _vm._s(_vm.$t("components.paymentSlip.digitableLine")) + " "
        ),
      ]),
      _c(
        "div",
        { staticClass: "typable_barcode" },
        [
          _c("IconBarcode", { staticClass: "icon" }),
          _c(
            "p",
            { staticClass: "line", class: { lineCompact: _vm.compact } },
            [_vm._v(" " + _vm._s(_vm.lineCode) + " ")]
          ),
          _c(
            "button",
            {
              staticClass: "btn-action btn-copy",
              on: { click: _vm.copyToClipboard },
            },
            [_vm._v(" " + _vm._s(_vm.copyButtonTitle) + " ")]
          ),
          !_vm.isDesktop
            ? _c(
                "a",
                {
                  staticClass: "btn-action btn-pdf",
                  attrs: { href: _vm.printLink, target: "_blank" },
                },
                [_vm._v(" PDF ")]
              )
            : _c(
                "button",
                { staticClass: "btn-action btn-pdf", on: { click: _vm.next } },
                [_vm._v(" PDF ")]
              ),
        ],
        1
      ),
      _vm.simple === false && _vm.dueAt !== null
        ? _c("p", { staticClass: "text-sm ml-1 mt-2" }, [
            _c("b", [
              _vm._v(
                _vm._s(this.$t("currency.sign")) +
                  " " +
                  _vm._s(parseInt(_vm.amount)) +
                  ",00"
              ),
            ]),
            _vm._v(
              " " + _vm._s(_vm.$t("components.paymentSlip.expireLabel")) + " "
            ),
            _c("b", [_vm._v(_vm._s(_vm.formatDate(_vm.dueAt)))]),
          ])
        : _vm._e(),
      _c("input", {
        attrs: { id: "line-code", type: "hidden" },
        domProps: { value: _vm.lineCode },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }