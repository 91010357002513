<template>

  <div class="m-2 p-4 pb-0">
    <iframe :src="getBankslipUrl" title="description" frameborder="0" class="iframe">
    </iframe>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { STORE, STORE_MODULE } from "@/store/constants/store";
import { CHECKOUT_MODULE, GET_ORDER } from "../store/constants/checkout";
import { BANKSLIP_PDF_VIEW } from "@/router/constants";

export default {
  name: BANKSLIP_PDF_VIEW,

  computed: {
    ...mapGetters({
        getOrder: `${CHECKOUT_MODULE}/${GET_ORDER}`,
        getProfile: `${STORE_MODULE}/${STORE}`,
    }),
    getBankslipUrl() {
      return (
        this.getProfile.subscription.last_bankslip_url + '&format=pdf'
        
      );
    },
  },
};
</script>
<style scoped>
  .iframe {
    height: 100vh;
    width: 100%;
  }
</style>
